import(/* webpackMode: "eager", webpackExports: ["SnackbarProviderWrapper"] */ "/app/src/app/_components/layout/SnackbarProviderWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRConfigProvider"] */ "/app/src/app/_components/layout/SwrConfigProviderWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AmplitudeInitClient"] */ "/app/src/features/tracking/amplitude/AmplitudeInitClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatadogInitClient"] */ "/app/src/features/tracking/datadog/_components/DatadogInitClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserpilotInitClient"] */ "/app/src/features/tracking/userpilot/_components/UserpilotInitClient.tsx");
