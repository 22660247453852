'use client';

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useEffect } from 'react';

export const AmplitudeInitClient = ({
  user,
}: {
  user: { id: string; email: string };
}) => {
  useEffect(() => {
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1,
    });
    amplitude.add(sessionReplayTracking);
    amplitude.init('59d584efe0ad1be5757d2422b9bfb9fa', user.id, {
      autocapture: { elementInteractions: true },
    });

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('email', user.email);
    amplitude.identify(identifyEvent);
  }, [user]);

  return null;
};
